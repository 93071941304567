.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #868686;
}

.tab-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0 24px 15px 24px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #868686;
  transition: opacity 0.2s ease-in-out;

  &.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 15px);
    width: 30px;
    height: 5px;
    background-color: #00b1b7;
    border-radius: 20px 20px 0px 0px;
  }

  & .done-icon {
    height: 16px;
    width: 16px;
  }

  &:hover {
    color: #00b1b7;
  }
}
.tab-item-custom {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 0 24px 15px 14px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #868686;
  transition: opacity 0.2s ease-in-out;

  &.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 20px);
    width: 30px;
    height: 5px;
    background-color: #00b1b7;
    border-radius: 20px 20px 0px 0px;
  }

  & .done-icon {
    height: 16px;
    width: 16px;
  }

  &:hover {
    color: #00b1b7;
  }
}
