.react-tabs {
  display: flex;
  flex-direction: column;

  ul {
    display: flex;
    flex-direction: row;
    gap: 0px;
    border: 0;
    justify-content: end;

    li {
      color: #00b1b7;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #8b8b8b;
      padding-bottom: 15px;
      padding-right: 20px;
      padding-left: 20px;
      &.react-tabs__tab--selected {
        &::after {
          content: '';
          position: absolute;
          left: calc(50% - 15px);
          bottom: 0px;
          width: 30px;
          height: 6px;
          border-radius: 20px 20px 0px 0px;
          background: #00b1b7;
        }
      }
    }
  }
}
