.card-wrapper {
    .card {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 20px;
        border: 1px solid #8b8b8b;
        &.annex-card,
        &.tiff-card,
        &.attachment-card {
            border: 1px dashed #8b8b8b;
            border-radius: 20px;
            padding: 16px;
            flex-direction: row;
            justify-content: space-between;
            .header {
                border: 0;
                text-align: left;
                justify-content: left;
                padding: 0;
                width: auto;
            }
            &.error {
                border: 2px dashed #d26e6e;
            }
        }
        &.attachment-card {
            width: 230px;
            height: 130px;
            flex-direction: column;
            .file-name {
                font-size: 14px;
                font-weight: 600;
                color: #868686;
                padding: 16px;
                background-color: #f0f0f0;
                border-radius: 20px;
            }
            .download-link {
                font-size: 14px;
                font-weight: 600;
                color: #00b1b7;
                padding: 16px;
                border-radius: 20px;
            }
            .delete-btn {
                padding: 16px;
                border-radius: 20px;
                width: 50px;
                height: 40px;
            }
        }
        &.tiff-card {
            justify-content: center;
            padding: 12px 0;
        }
        .header {
            font-size: 21px;
            font-weight: 600;
            color: #00b1b7;
            border-bottom: 1px solid #d9d9d9;
            padding-bottom: 16px;
            width: 100%;
            text-align: center;
            justify-content: center;
            span {
                font-size: 21px;
                font-weight: 600;
                color: #00b1b7;
            }
        }
        .body {
            width: 100%;
        }
    }
}

.download-cards {
    gap: 32px;
    padding: 32px 0;
    .card {
        width: 280px;
        height: 240px;
        ul {
            margin: 0;
            justify-content: initial;
            li {
                border-bottom: 0;
            }
        }
    }
}

.photo-cards,
.attachments-cards {
    padding: 32px 0;
}

.annex-file,
.tiff-file {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 20px;
    width: auto;
    min-width: 300px;
    .no-annex-file {
        padding: 16px;
    }
    .annex-text {
        padding: 16px;
        .text {
            font-size: 14px;
            font-weight: 600;
            color: #00b1b7;
        }
    }
}

.tiff-file {
    padding: 24px 0;
}

.icon-wrapper {
    padding: 16px;
    border-radius: 20px;
    background-color: #f4f5f7;
    color: #868686;
}
