@import "./assets/fonts/fonts.css";
@import "./assets/styles/base.scss";
@import "./assets/styles/form.scss";
@import "./assets/styles/scroll.scss";

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

body {
  font-family: "Montserrat-Medium";
  margin: 0;
  padding: 0;
}