.profile-page {
  background: #ffffff;
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  p {
    margin: 0;
  }
}

.profile-info {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #ffffff;
  .left-and-right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 60px;
    padding: 60px 0;
    .left-section {
      .left-section-header {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        color: rgba(134, 134, 134, 1);
      }
      .profile-with-avatar {
        width: 200px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 20px;
        padding-bottom: 30px;
        .change-image-with-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 14px;
          margin-top: 10px;
        }
        .section-avatar {
          width: 200px;
          height: 140px;
          display: inline-block;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 20px;
          }
        }
      }
      .profile-without-avatar {
        width: 200px;
        height: 140px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 30px;
        gap: 10px;
        align-items: center;
        border-radius: 20px;
        border: 2px dashed #00b1b7;

        & svg {
          width: 60px;
          height: 60px;
          background-color: #f4f5f7;
          border-radius: 20px;
          padding: 20px;
          color: #868686;
        }
      }
    }
    .right-section {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 10px;
    }
  }
}
.users-header {
  .dateFromTo {
    display: flex;
    gap: 20px;
  }
}
.profile-footer {
  border-top: 2px solid rgb(237, 240, 243);
  padding-bottom: 30px;
  padding-top: 20px;
  gap: 10px;
  display: flex;
  justify-content: end;
  width: 95%;
}
