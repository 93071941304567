::-webkit-scrollbar {
	width: 12px;
    height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #00B1B7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: darken(#00B1B7, 10);
}