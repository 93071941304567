textarea {
    display: flex;
    width: 490px;
    padding: 12px 10px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    border: 1px solid #868686;
    color: #868686;
    font-family: "Montserrat-Medium";
    resize: vertical;
    &::placeholder {
        color: #dfdfdf;
    }
    &:hover,
    &:active {
        border: 1px solid #00b1b7;
        outline: 0;
    }
    &:focus-visible {
        outline: 0;
    }
    &.full {
        width: -webkit-fill-available;
    }
}

.textarea-wrapper {
    .blue-link {
        font-size: 14px;
        color: #00b1b7;
        text-align: end;
    }
}