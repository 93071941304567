.lm-bm-header {
    display: flex;
    align-items: center;
    gap: 16px;
    width: 1000px;
    > div {
        &:first-child {
            content: '';
            border-right: 1px solid #dfdfdf;
        }
    }
}
