.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  &.custom {
    gap: 1px;
  }
  &-header {
    display: flex;
    border-bottom: 1px solid #868686;
    border-radius: 40px;
    background-color: #fff;

    th {
      width: 100%;

      flex: 1 1 118px;
      justify-content: center;
      color: #868686;
      font-size: 14px;
      font-weight: 600;
      padding-top: 8px;
      padding-bottom: 8px;

      & .sort-icon {
        cursor: pointer;
        height: 22px;
        width: 22px;
        &:hover {
          color: #00b1b7;
        }
      }
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &-row {
    display: flex;
    align-items: center;
    border-radius: 40px;
    background-color: #f4f5f7;

    td {
      display: flex;
      flex: 1 1 118px;
      justify-content: center;
      color: #868686;
      font-weight: 400;
      font-size: 14px;
      padding-top: 16px;
      padding-bottom: 16px;
      text-align: center;
      button {
        border: 0;
      }

      & .all-icons {
        display: flex;
        gap: 4px;
        height: 24px;

        &.delete-icon {
          height: 22px;
          cursor: pointer;
        }

        &.edit-icon {
          height: 22px;
          cursor: pointer;
        }

        &.view-icon {
          height: 22px;
          cursor: pointer;
        }

        &.upload-icon {
          height: 20px;
          cursor: pointer;
        }

        & .copy-icon {
          height: 22px;
          cursor: pointer;
        }

        & .edit-icon :hover {
          fill: #00b1b7;
          cursor: pointer;
        }

        .delete-icon :hover {
          fill: #00b1b7;
          cursor: pointer;
        }

        .view-icon :hover {
          fill: #00b1b7;
          cursor: pointer;
        }

        .upload-icon :hover {
          fill: #00b1b7;
          cursor: pointer;
        }
        .copy-icon :hover {
          stroke: #00b1b7;
          cursor: pointer;
        }
      }

      & .import-and-export-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-left: 3px solid rgba(223, 223, 223, 1);
        padding-left: 10px;
        margin-left: 10px;
        position: relative;

        & .import-icon {
          height: 18px;
          width: 18px;
          cursor: pointer;
          color: rgba(0, 188, 19, 1);
        }
        & .import-icon-red {
          height: 18px;
          width: 18px;
          cursor: pointer;
          color: red;
        }
        .import-icon :hover {
          fill: #00b1b7;
          cursor: pointer;
        }
        & .export-icon {
          height: 18px;
          width: 18px;
          cursor: pointer;
          color: rgba(0, 188, 19, 1);
        }
        & .export-icon-disabled,
        & .import-icon-disabled {
          height: 18px;
          width: 18px;
          cursor: not-allowed;
          color: #8a95a3;
        }

        .export-icon :hover {
          fill: #00b1b7;
          cursor: pointer;
        }

        .import-dropdown-content {
          position: absolute;
          top: calc(100% + 5px);
          left: 0;
          background-color: white;
          border: 1px solid #ccc;
          padding: 5px;
          border-radius: 4px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          z-index: 1;
          p:hover {
            color: #03c2c9;
            cursor: pointer;
            font-weight: 700;
          }
        }
      }
    }
  }

  &.table-lines-between {
    gap: 0;
    .table-header {
      align-items: center;
      background-color: #f4f5f7;
    }
    tr {
      border-radius: 0;
      border-bottom: 0;
      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 10px;
          }
          &:last-child {
            border-radius: 0 0 10px 0;
          }
        }
      }
      &.separator {
        border-top: 1px solid #dfdfdf;
      }
    }
    td,
    th {
      position: relative;
      input,
      textarea {
        &:not(.number) {
          background: transparent;
          width: initial;
        }
      }
    }
    th {
      background-color: #f4f5f7;
      color: #1e1e1e;
      border-radius: 0px;
      width: auto;
      &:nth-child(1) {
        flex: 250px;
        justify-content: flex-start;
        text-align: start;
      }
      &:nth-child(2) {
        flex: auto;
      }
      &:nth-child(3),
      &:nth-child(5) {
        flex: 175px;
      }
      &:nth-child(4) {
        flex: auto;
      }
      &:nth-child(6) {
        flex: auto;
      }
      &:nth-child(7) {
        flex: 450px;
      }
      &:nth-child(8) {
        flex: 30px;
      }
      &:first-child {
        border-radius: 10px 0px 0 0;
        &:before {
          content: none;
        }
      }
      &:last-child {
        border-radius: 0 10px 0 0;
        &:after {
          content: none;
        }
      }
      &.no-border-radius {
        border-radius: 0;
      }
    }
    td {
      &:nth-child(1) {
        flex: 250px;
        width: 250px;
        justify-content: flex-start;
        text-align: start;
      }
      &:nth-child(2) {
        flex: auto;
      }
      &:nth-child(3),
      &:nth-child(5) {
        flex: 175px;
      }
      &:nth-child(4) {
        flex: auto;
      }
      &:nth-child(6) {
        flex: auto;
      }
      &:nth-child(7) {
        flex: 450px;
        text-align: end;
        justify-content: end;
        &.center {
          text-align: center;
          justify-content: center;
        }
        textarea {
          width: 400px;
        }
      }
      &:nth-child(8) {
        flex: 30px;
      }

      &:first-child {
        &:before {
          content: none;
        }
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      &.actions {
        width: max-content;
      }
      &.empty {
        justify-content: center;
      }
    }
    .line {
      width: 80%;
      margin: 0 auto;
    }
  }
  .double-text {
    display: flex;
    flex-direction: column;
    div,
    span {
      &:first-child {
        color: #1e1e1e;
        font-weight: 600;
        font-size: 14px;
      }
      &:last-child {
        color: #878787;
        font-weight: 400;
        font-size: 14px;
      }
    }
    &.error {
      span,
      div {
        color: #d26e6e;
      }
    }
  }
}
.additional-access-icon {
  width: 22px;
  position: absolute;
  margin-left: 78px;
  margin-top: 12px;
  height: 22px;
}
.delete-icon {
  cursor: pointer;
  :hover {
    fill: #00b1b7;
  }
}
.minus-icon {
  cursor: pointer;
  :hover {
    fill: #00b1b7;
  }
}

.plus-icon {
  cursor: pointer;
  :hover {
    fill: #00b1b7;
  }
}

.view-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.view-icon :hover {
  fill: #00b1b7;
  cursor: pointer;
}

.edit-icon {
  height: 22px;
  cursor: pointer;
}

.edit-icon :hover {
  fill: #00b1b7;
  cursor: pointer;
}
.delete-icon {
  height: 22px;
  cursor: pointer;
}

.delete-icon :hover {
  fill: red;
  cursor: pointer;
}

.table-users {
  display: flex;
  border-radius: 40px;
  align-items: center;
  height: 50px;
  background-color: #e288001a;

  td {
    display: flex;
    flex: 1 1 118px;
    justify-content: center;
    color: #555555;
    font-weight: 400;
    font-size: 14px;
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    &:first-child {
      font-size: 16px;
      border-radius: 50px;
      max-width: 90px;
      justify-content: center;
    }

    &:last-child {
      max-width: 50px;
      justify-content: center;
    }

    button {
      border: 0;
    }
  }
}
