.react-modal-class {
    position: absolute;
    inset: 40px;
    border: none;
    background: transparent;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}

.react-inner-modal-class {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
}

.MuiModal-root {
    &.templates-modal {
        .popup {
            width: 600px;
        }
    }
    .MuiBackdrop-root {
        background-color: rgba(23, 31, 31, 0.95);
        backdrop-filter: blur(2px);
    }
}

.MuiDialog-container {
    .MuiPaper-root  {
        border-radius: 16px;
    }
}
