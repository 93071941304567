.input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  width: 300px;
  .blue-link {
    font-size: 14px;
    color: #00b1b7;
  }
  &.error {
    input {
      border: 1px solid #d26e6e;
    }
    .input-description {
      color: #d26e6e;
    }
  }
  &.w-100 {
    width: 100%;
  }
}

label {
  font-size: 14px;
  color: #868686;
}

.upper-right-text {
  color: #868686;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 100%;
  margin-top: 10px;
}

.icon-exist {
  input {
    text-indent: 25px;
  }
  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    width: 20px;
    height: 24px;
    transform: translate(0, -50%);
    color: #868686;
    &.icon-left {
      left: 10px;
      right: 0px;
    }
    &.icon-right {
      right: 10px;
      left: inherit;
      &:hover {
        cursor: pointer;
      }
    }
    svg {
      width: 20px;
    }
  }
}

input {
  border: 1px solid #868686;
  border-radius: 4px;
  padding: 10px;
  color: #868686;
  font-family: "Montserrat-Medium";
  width: 278px;
  &:-webkit-autofill {
    background-color: transparent;
  }
  &::placeholder {
    color: #dfdfdf;
  }
  &:hover,
  &:active {
    border: 1px solid #00b1b7;
    outline: 0;
  }
  &:focus-visible {
    outline: 0;
  }
  &[type="password"] {
    &::placeholder {
      color: #dfdfdf;
    }
    &:hover,
    &:active {
      border: 1px solid #00b1b7;
      outline: 0;
    }
    &:focus-visible {
      outline: 0;
    }
    &[type="password"] {
      &::placeholder {
        -webkit-text-security: disc !important;
      }
    }
  }
  &[disabled] {
    background: #efefef;
  }
  &.number {
    width: 40px;
    text-align: center;
  }
}

.input-description {
  font-size: 12px;
  color: #868686;
}

.labels {
  display: flex;
  justify-content: space-between;
}

[type="checkbox"] {
  &:checked {
    position: absolute;
    left: -9999px;
    + {
      label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        font-family: "Montserrat-Regular";
        display: inline-block;
        color: #868686;
        font-size: 14px;
        font-weight: 400;
        text-transform: initial;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 16px;
          height: 16px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
          background: #fff;
          background: #00bc13;
          border: 0;
        }
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          left: 6px;
          width: 2px;
          height: 5px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          opacity: 1;
        }
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
  &:not(:checked) {
    position: absolute;
    left: -9999px;
    + {
      label {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        font-family: "Montserrat-Regular";
        display: inline-block;
        color: #868686;
        font-size: 14px;
        font-weight: 400;
        text-transform: initial;
        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 16px;
          height: 16px;
          border: 1px solid #868686;
          border-radius: 4px;
          background: #fff;
        }

        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 4px;
          left: 6px;
          width: 2px;
          height: 5px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
          transition: 0.2s ease-in;
        }
        &:hover {
          font-weight: 600;
          &:before {
            border: 1px solid #00bc13;
          }
        }
      }
    }
  }
  &:disabled {
    pointer-events: none;
    + {
      label {
        opacity: 1;
        filter: grayscale(1);
        pointer-events: none;
        &:before,
        &:after {
          opacity: 0.4;
        }
        &:hover {
          cursor: initial;
          font-family: "Montserrat-Regular";
          &:before {
            border: 1px solid #e0e0e0;
          }
        }
      }
    }
  }
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  text-align: justify;
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    color: #868686;
    font-weight: 600;
  }
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid #868686;
  background: #ffffff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: #ffffff;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:checked + label:before {
  background-color: #00bc13;
  border-color: #00bc13;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  background-color: #ffffff;
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.checkbox-wrapper,
.radio-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin: 4px;
}

.label-input {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 14px;
  color: #868686;
}
