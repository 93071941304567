.new-image-item {
    width: 250px;
    height: 150px;
    border: 1px dashed #8b8b8b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border-radius: 20px;
    box-sizing: border-box;
    img {
        padding: 16px;
        background-color: #f4f5f7;
        border-radius: 20px;
        width: 40px;
        height: 40px;
    }
}

.upload-image-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    &.error {
        svg {
            path {
                fill: #d26e6e;
            }
        }
    }
}

.image-item {
    width: 250px;
    height: 150px;
    border-radius: 20px;
    position: relative;
    img {
        width: 250px;
        height: 150px;
        object-fit: cover;
        border-radius: 20px;
    }
    .image-item__btn-wrapper {
        color:#868686;
        .delete {
            svg {
                position: absolute;
                bottom: 4px;
                right: 3px;
                background-color: #dfdfdf;
                border-radius: 50%;
                &:hover {
                    cursor: pointer;
                    path {
                        fill: #d26e6e;
                    }
                }
            }
        }
        .zoom {
            svg {
                position: absolute;
                top: 4px;
                right: 3px;
                background-color: #dfdfdf;
                border-radius: 50%;
                &:hover {
                    cursor: pointer;
                    path {
                        fill: #00b1b7;
                    }
                }
            }
        }
        .check {
            svg {
                position: absolute;
                top: 4px;
                left: 3px;
                background-color: #dfdfdf;
                border-radius: 50%;
                &.active {
                    background-color: #00b1b7;
                    path {
                        fill: #ffffff;
                    }
                }
            }
        }
        .download {
            svg {
                position: absolute;
                bottom: 4px;
                left: 3px;
                background-color: #dfdfdf;
                border-radius: 50%;
                &:hover {
                    cursor: pointer;
                    path {
                        fill: #00b1b7;
                    }
                }
            }
        }
    }
}

.image-gallery {
    margin: auto;
}
.image-gallery-icon {
    &:hover {
        outline: 0;
        border: 0;
    }
}
.image-gallery-thumbnail.active {
    border: 0 !important;
    img {
        transform: scale(1.1);
    }
}

.image-gallery-thumbnails-wrapper {
    max-width: 400px;
}
.image-gallery-thumbnails-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    gap: 16px;
    button {
        width: 60px;
        height: 60px;
        .image-gallery-thumbnail-inner {
            width: 60px;
            height: 60px;
            .image-gallery-thumbnail-image {
                object-fit: cover;
                filter: brightness(0.5);
                border-radius: 5px;
            }
        }
        &:hover,
        &:active,
        &:focus {
            .image-gallery-thumbnail-inner {
                .image-gallery-thumbnail-image {
                    border: 1px solid #00b1b7;
                    filter: brightness(1);
                }
            }
        }
        &.image-gallery-thumbnail {
            &.active {
                img {
                    transform: none;
                    filter: brightness(1);
                    border: 1px solid #00b1b7;
                }
            }
        }
    }
}

.image-gallery-right-nav {
    content: url("../images/icons/Right.svg");
    padding: 0 !important;
    right: -50px;
}

.image-gallery-left-nav {
    content: url("../images/icons/Left.svg");
    padding: 0 !important;
    left: -50px;
}
.image-gallery-image {
    border-radius: 20px;
}

.image-gallery-content {
    .image-gallery-slide {
        .image-gallery-image {
            max-height: calc(100vh - 170px);
        }
    }
}

.file-info {
    border-radius: 20px;
    background: rgba(244, 245, 247, 0.95);
    height: 100%;
    min-width: 315px;
    max-width: 315px;
    width: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    .icon {
        color:#868686;
    }
    .header {
        color: #00b1b7;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        border-bottom: 2px solid #00b1b7c2;
        padding-bottom: 4px;
        svg {
            &:hover {
                cursor: pointer;
                filter: brightness(0.5);
            }
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        flex-grow: 1;
        max-height: max-content;
        overflow: auto;
    }
    .footer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
    }
}

.image-gallery-wrapper {
    display: flex;
    gap: 64px;
    position: relative;
    width: 70%;
    height: 80%;
    margin: auto;
    justify-content: center;
    inset: 60px 0;
}

.preview-image {
    width: 300px;
    object-fit: cover;
    height: 300px;
    border-radius: 20px 20px 0 0;
}

.images-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
        width: 60px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid #6767673d;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        object-fit: cover;
        margin-right: -30px;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            margin: 0;
            border: 1px solid #00b1b7;
            cursor: pointer;
        }
    }
}
