.details-container {
  max-height: 73vh;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 16px 16px 16px 16px;
  overflow-y: auto;
  overflow-x: hidden;

  .title {
    font-size: 20px;
    font-weight: 500;
    color: #868686;
    margin-left: 6px;
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
