.notifications-icon {
    position: relative;
    span {
        position: absolute;
        top: -7px;
        right: -7px;
        width: 10px;
        height: 10px;
        background-color: #e28800;
        color: #ffffff;
        border-radius: 50%;
        padding: 2px 2px;
        font-size: 10px;
        text-align: center;
        &.loading {
            padding: 3px;
        }
    }
    svg {
        color: #868686;
    }
    &:hover {
        cursor: pointer;
        svg {
            color: #e28800;
        }
    }
}

.notification-user {
    display: flex;
    gap: 10px;
    .role-label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 60px;
        border-radius: 50px;
        font-weight: 500;
        color: #555555;
    }
    .info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
    }
}

.notification-popup {
    width: 1000px;
    text-align: start;
    .action {
        text-transform: capitalize;
    }
}
