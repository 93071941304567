.error-404 {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    span {
        &:nth-child(1),
        &:nth-child(2) {
            font-size: 7rem;
            font-weight: 600;
            color: #ffffff;
        }
    }
    a {
        font-size: 1.5rem;
        font-weight: 500;
        color: #ffffff;
        text-decoration: underline;
        &:hover {
            color: #00b1b7;
        }
    }

}
