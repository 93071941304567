@font-face {
    font-family: "Montserrat-Bold";
    src: local("Montserrat-Bold"),
     url("../fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Regular";
    src: local("Montserrat-Regular"),
     url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Medium";
    src: local("Montserrat-Medium"),
     url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}