.custom-accordion {
  .accordion {
    border: none;
    border-radius: 0;
  }

  .accordion__panel {
    padding: 14px 0px;
  }

  .accordion__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: inherit;
    border-radius: 20px;
    background: #e8e8e8;
    padding: 14px 16px;
    transition: 0.3s ease-in;
    &::before {
      content: none;
    }
    &::after {
      display: inline-block;
      content: "";
      height: 6px;
      width: 6px;
      margin-right: 12px;
      border-bottom: 2px solid currentColor;
      border-right: 2px solid currentColor;
      transform: rotate(45deg);
    }
    &[aria-expanded="true"] {
      &::after {
        transform: rotate(-135deg);
      }
    }
    &.error {
      background: #d26e6e66;
    }
  }
  .accordion__item {
    padding: 4px 0;
    + {
      .accordion__item {
        border-top: 0;
      }
    }
  }
}
.custom-accordion-item-panel {
  padding: 14px 0px;
  display: flex;
  flex-wrap: wrap;
}

.accordion-body-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 8px;
  border-radius: 20px;

  .accordion-title {
    display: flex;
    flex-direction: column;
    gap: 6px;
    white-space: nowrap;
    span {
      &:first-child {
        font-size: 14px;
        font-weight: 600;
        color: #1e1e1e;
      }
      &:last-child {
        font-size: 14px;
        font-weight: 400;
        color: #868686;
      }
    }
  }
  .numberId {
    font-size: 14px;
    font-weight: 600;
    min-width: 32px;
    border-right: 2px solid #d9d9d9;
  }
  .right-content {
    font-size: 14px;
    font-weight: 500;
    color: #868686;
    text-align: right;
    &.w-60 {
      min-width: 60%;
    }
    &.w-40 {
      min-width: 40%;
    }
    white-space: nowrap;
  }
  &.new-line {
    flex-wrap: wrap;
    text-align: left;
    justify-content: left;
    .right-content {
      width: 100%;

      .input-wrapper {
        width: 100%;
      }
    }
    .line {
      margin: 0;
    }
  }
  &.bgGrey {
    background-color: #f4f5f7;
  }
  &.no-border-radius-bottom {
    border-radius: 20px 20px 0 0;
  }
  &.error {
    .accordion-title {
      span {
        color: #d26e6e;
      }
    }
  }
}

.accordion-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
  .accordion-title {
    display: flex;
    flex-direction: column;
    gap: 6px;
    white-space: nowrap;
    span {
      &:first-child {
        font-size: 18px;
        font-weight: 600;
        color: #1e1e1e;
      }
      &:last-child {
        font-size: 16px;
        font-weight: 500;
        color: #868686;
      }
    }
  }
  .accordion-title-validation {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 60px;
    height: 40px;
    background-color: #a700e21a;
    &:first-child {
      font-size: 16px;
      color: #555555;
    }
  }
  .accordion-user-properties {
    color: #555555;
    padding-right: 10px;
  }
  .numberId {
    font-size: 21px;
    font-weight: 600;
    min-width: 32px;
    border-right: 2px solid #d9d9d9;
  }
}

.line {
  height: 2px;
  width: 100%;
  background-color: #dfdfdf;
  margin-right: 20px;
}

.line-blue {
  height: 2px;
  width: 100%;
  background-color: #ceebee;
  margin-right: 20px;
}

.accordion-body-wrapper-view {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 20px;
  width: 45%;
  .accordion-title {
    display: flex;
    flex-direction: column;
    white-space: wrap;
    gap: 4px;

    span {
      &:first-child {
        font-size: 14px;
        font-weight: 600;
        color: #1e1e1e;
      }
      &:last-child {
        font-size: 14px;
        font-weight: 400;
        color: #868686;
        margin-bottom: 8px;
      }
    }
  }
  .accordion-right-part {
    border-left: 2px solid #d9d9d9;
    padding-left: 14px;
  }
  .accordion-last-items {
    color: #868686;
    font-size: 14px;
    font-weight: 600;
  }

  .numberId {
    font-size: 14px;
    font-weight: 600;
    min-width: 32px;
  }
}
.accordion-body-wrapper-view2 {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 20px;
  width: 100%;
  .accordion-title {
    display: flex;
    flex-direction: column;
    white-space: wrap;
    gap: 4px;

    span {
      &:first-child {
        font-size: 14px;
        font-weight: 600;
        color: #1e1e1e;
      }
      &:last-child {
        font-size: 14px;
        font-weight: 400;
        color: #868686;
        margin-bottom: 8px;
      }
    }
  }
  .accordion-right-part {
    border-left: 2px solid #d9d9d9;
    padding-left: 14px;
  }
  .accordion-last-items {
    color: #868686;
    font-size: 14px;
    font-weight: 600;
  }

  .numberId {
    font-size: 14px;
    font-weight: 600;
    min-width: 32px;
  }
}
.accordion-view-disabled-icon {
  display: flex;
  gap: 6px;
  padding-top: 6px;
}
