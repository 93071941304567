.photo-block {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 60%;
    height: 60%;
    object-fit: cover;
  }
}

.annex-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 100%;
  img {
    border-radius: 0px;
    width: 60%;
    height: 60%;
    object-fit: cover;
    display: block;
    margin: auto;
  }
}
