input[type="checkbox"] {
	&.flipswitch {
		position: relative;
		width: 40px;
		height: 20px;
		min-width: 40px;
		-webkit-appearance: initial;
		border-radius: 30px;
		outline: none;
		cursor: pointer;
		background-color: #00B1B7;
		transition: all 0.3s ease-in 0s;
        border: 0;
		&:after {
			right: 4px;
            top: 4px;
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			transition: all 0.3s ease-in 0s;
			border-radius: 60px;
			background-color: white;
            border: 1px solid white;
		}
		&:checked {
			background-color: #00B1B7;
			transition: all 0.3s ease-in 0s;
            left: initial;
			&:after {
				left: 10%;
				content: "";
			}
			+ label {
				font-weight: 400;
			}
		}
        &:not(:checked) {
            background-color: #868686;
            left: initial;
            &:after {
                background-color: transparent;
                border: 1px solid white;
            }
        }
        &:hover {
            background-color: #005D67;
            &:after {
                background-color: transparent;
                border: 1px solid white;
            }
        }
	}
	&.green {
		&:after {
			left: 4px;
		}
		background-color: #00D57C;
		&:checked {
			&:after {
				left: inherit;
				right: 4px;
			}
			background-color: #00D57C;
		}
		&:hover {
            background-color: #005431;
        }
	}
}

.swither-wrapper {
    display: flex;
    gap: 16px;
	align-items: center;
	span {
		white-space: nowrap;
	}
}