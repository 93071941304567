.template-attributes {
    padding: 14px 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #868686;
    max-height: 312px;
    overflow: auto;
}

.checkbox-children {
    margin-left: 15px;
}

.export-table,
.templates-table {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ffffff;
    padding: 16px;
    border-radius: 20px;
}
