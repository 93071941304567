.box-map {
    display: flex;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.8);
    &.active {
        background-color: #00b1b7;
        svg {
            path {
                fill: #ffffff;
            }
        }
    }
    &.pointer {
        &:hover {
            cursor: pointer;
        }
    }
    &.blur {
        filter: blur(3px);
        pointer-events: none;
    }
}

.upper-filter {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0%);
}

.right-box {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0%, -50%);
    display: flex;
    width: 360px;
    padding: 32px;
    flex-direction: column;
    gap: 16px;
    border-radius: 20px 0px 0px 20px;
    background: #f4f5f7;
    box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.1);
    max-height: calc(100vh - 350px);
    overflow: auto;
    .header {
        border-bottom: 2px solid #00b1b770;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
        .title {
            color: #00b1b7;
            font-weight: 600;
        }
    }
    .body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        color: #868686;
        .color-item {
            width: 18px;
            height: 18px;
            border-radius: 50%;
            display: flex;
        }
    }
    .closeBtn {
        background-image: url("../../images/icons/close_24px.svg");
        cursor: pointer;
        width: 24px;
        height: 24px;
        &:hover {
            filter: brightness(1.5);
        }
    }
}
