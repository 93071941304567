.nts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 20px;

  &-title {
    font-size: 26px;
    color: #868686;
    font-weight: 600;
  }

  &-header {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  &-table {
    border-radius: 20px;
    width: 100%;
  }

  & .text-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  &-line {
    height: 2px;
    background-color: #f4f5f7;
    width: 100%;
    border: none;
  }
  &-footer {
    display: flex;
    justify-content: end;
    width: 100%;
  }
}

.loadMore-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.loadMore-button {
  background: #fff;
  color: #00b1b7;
  margin-top: 7px;
  letter-spacing: 0.28px;
}

.addUser-button {
  background-color: #f4f5f7;
  color: #00b1b7;
  letter-spacing: 0.28px;
}

.viewAllReports-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  background-color: #f4f5f7;
  color: #00b1b7;
  letter-spacing: 0.28px;
  .view-icon {
    height: 14px;
  }
}

.validation-status {
  display: flex;
  align-items: center;
  gap: 3px;
}
