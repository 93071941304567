@import './accordion.scss';

.form-wrapper {
    background-color: #FFF;
    border-radius: 20px;
    padding: 16px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        span {
            &:first-child {
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                color: #868686;
            }
            &:last-child {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                color: #868686;
            }
        }
    }
}