.info-item {
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 32px;
    border-radius: 20px;
    background: #f4f5f7;
    width: fit-content;
    .titles {
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            right: -16px;
            top: 0;
            height: 100%;
            border-right: 2px solid #d9d9d9;
        }
        span {
            &:first-child {
                color: #1e1e1e;
                font-weight: 600;
            }
            &:last-child {
                color: #878787;
                font-weight: 400;
            }
        }
    }
    &.error {
        .titles {
            span {
                &:first-child {
                    color: #d26e6e;
                    font-weight: 600; 
                }
                &:last-child {
                    color: #d26e6e;
                    font-weight: 400;
                }
            }
        }
    }
}
