.custom-datepicker {
    display: flex;
    height: 40px;
    width: 300px;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    .MuiInputBase-root {
        &:hover {
            border: 1px solid #00b1b7;
        }
    }
    .MuiInputBase-input {
        &:hover {
            border: 0;
        }
    }
    .MuiButtonBase-root {
        border: 0;
        background-color: transparent;
        &:hover, 
        &:active, 
        &:focus {
            background-color: transparent;
            svg {
                path {
                    fill: #00b1b7;
                }
            }
        }
    }

    .css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
        padding: 3px;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: #868686;
    }
    .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
        flex-direction: row-reverse;
        color: #868686;
        width: 100%;
    }
    &.full {
        width: 100%;
        .MuiInputBase-root {
            width: 100%;
        }
    }
}

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled {
    background: #e6e6e6;
}
