.custom-input {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 20px;
    background: #00b1b7;
    color: #f0f0f0;
    border: 1px solid #00b1b7;
    font-size: 14px;
    font-family: "Montserrat-Medium";
    font-weight: 600;
    padding: 8px 16px;
    transition: ease-out 0.3s;
    &:hover {
        background: transparent;
        border: 1px solid #00b1b7;
        label {
            color: #00b1b7;
        }
        svg {
            path {
                fill: #00b1b7;
            }
        }
    }
    &:active,
    &:focus {
        background: #005d67;
    }
    &.without-bg {
        background: transparent;
        color: #00b1b7;
        border: none;
        label {
            color: #00b1b7;
        }
        &:hover {
            background: #00b1b7;
            color: #f0f0f0;
            label {
                color: #f0f0f0;
            }
            svg {
                path {
                    fill: #f0f0f0;
                }
            }
        }
        &:active,
        &:focus {
            background: #005d67;
        }
    }
}

.custom-input label {
    cursor: pointer;
    color: #f0f0f0;
    display: flex;
    align-items: center;
    gap: 6px;
}

.custom-input input.hidden {
    display: none;
}

.custom-input .status {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f1f1f1;
    padding: 5px;
    width: 100%;
    text-align: center;
}

.custom-input.input-uploading .status {
    display: block;
}

.no-files {
    text-align: center;
    padding: 42px 0;
    margin: auto;
}

.files-wrapper {
    min-height: 100px;
    li {
        border-bottom: 0;
        span {
            text-align: left;
        }
    }
}
