.custom-select-container {
	width: 300px;
	text-align: start;
	white-space: normal;
	&.full {
		width: 100%;
	}

	.css-1u9des2-indicatorSeparator {
		display: none;
	}
	.css-1im77uy-control {
		border: 1px solid #868686;
		border-radius: 4px;
		color: #868686;
		&:hover {
			cursor: pointer;
			border: 1px solid #00b1b7;
		}
	}
	.css-1hpbggg-control {
		border-radius: 4px;
		border: 0;
	}

	.css-lkh0o5-menu {
		z-index: 10000;
	}
}

.select-wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	width: 300px;
	.label {
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: #868686;
	}
	&.w-100 {
		.custom-select-container {
			width: 100%;
		}
	}
	.type-label {
		width: 160px;
		padding: 8px;
		border: 1px solid #ccc;
		border-radius: 4px;
		color: #868686;
		height: 30px;
		display: flex;
		align-items: center;
	}
	.user-label {
		width: 400px;
		padding: 2px;
		border: 1px solid #ccc;
		border-radius: 4px;
		color: #868686;
	}
}

.css-1jqq78o-placeholder {
	div {
		display: flex;
		align-items: center;
		gap: 5px;
	}
}
