.menu-title {
  display: flex;
  width: -webkit-fill-available;
  &.active {
    border-radius: 12px;
    background: #00b1b7;
    &:hover {
      a {
        color: #ffffff;
      }
    }
  }
  svg {
    width: 24px;
  }
}

.user-info {
  padding: 16px 0;
  border-bottom: 2px solid #005d67;
  width: calc(100% - 32px);
  margin: auto;
  align-items: end;
  color:#ffffff;
  cursor: pointer;

  .name {
    color: #f0f0f0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    white-space: wrap;
  }

  .name:hover {
    color: #00b1b7;
  }
  .avatar {
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
  }
  .avatar img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
  }
  .role {
    color: #868686;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .user-icon {
    svg {
      color: #868686;
      width: 50px;
      height: 50px;
    }
  }
}
