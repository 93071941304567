.map-container {
    height: 400px;
    &.full {
        position: relative;
        height: 100vh;
    }
    &.full-public {
        position: relative;
        height: calc(100vh - 60px);
    }
    &.width-500 {
        width: 500px;
    }
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-top-right {
    display: none;
}

.map-wrapper {
    position: relative;
    .mapbox-control {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        border-radius: 0 0 8px;
        box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.1);
        padding: 10px;
    }
}

.mapbox-popup {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    width: 250px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0 8px 8px 0;
    box-shadow: 0px 20px 25px 0px rgba(76, 103, 100, 0.1);
    padding: 10px;
    &.full {
        width: 500px;
        overflow: auto;
    }
}
